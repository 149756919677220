window.temStatus = function(temp){
    temp = temp.toFixed();
    var temp_cond = 'danger';
    if(temp >=25 && temp <= 27)
        temp_cond = 'warning';
    else if(temp >= 20 && temp <= 24)
        temp_cond = 'good';
    else if(temp < 20)
        temp_cond = 'perfect';
    return temp_cond;
}

window.humStatus = function(hum){
    hum = hum.toFixed();
    var hum_cond = 'low';
    if(hum >=50 && hum  <= 70)
        hum_cond = 'normal';
    else if(hum  > 70)
        hum_cond = 'high';
    return hum_cond;
}

window.coStatus = function(co2){
    co2 = co2.toFixed();
    var co_cond = 'low';
    if(co2 >=2000 && co2  <= 3000)
        co_cond = 'normal';
    else if(co2  > 3000)
        co_cond = 'high';
    return co_cond;
}

window.showMessage = function (class_type, type,  message){
    $('#message').empty();
    $('#message').append('<div class="alert alert-'+class_type+' alert-dismissible fade show" role="alert"><ul class="p-0 m-0" style="list-style: none;"><li><b>' + type.charAt(0).toUpperCase() + type.slice(1) + ': </b>' + message + '</li></ul></div>');
}
